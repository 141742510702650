import React from "react";
import { Link } from "react-router-dom";

import Bg from "../../images/wp/wp-bg.png";
import abimg1 from "../../images/wp/wp-1.png";
import abimg2 from "../../images/wp/wp-2.png";
import abimg3 from "../../images/wp/wp-3.png";
import abt1 from "../../images/allanB/about-us.jpg";
import prop5 from "../../images/allanB/prop5.jpg";
import hmint from "../../images/allanB/home-intro.jpg"

const About = (props) => {
  return (
    <section
      id="about"
      className="wp-section pt-8 rmb-10"
      //   style={{ backgroundImage: `url(${prop5})` }}
    >
      <div className="container">
        {/* <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="wp-images rmb-150">
              <div className="experience-year">
                <span>Over</span>
                <span className="number">25</span>
                <h4>Years of Experience</h4>
              </div>
              <img src={abt1} alt="Wordpress" />
              {/* <img src="/public/images/allanB/prop1.jpg" alt="Wordpress"/> 

              <img src={abt1} alt="Wordpress" />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="wp-content ml-50 rml-0 rpt-30">
              <div className="section-title mb-10">
                <h6>Why Choose Us</h6>
                {/*  <h2>A small efficient <br/>
                                    <span>interior</span> <br />
                                    <span className="thin">designing team</span>
                                </h2> 
                <p>
                  Real Estate may be your single largest asset. Having a trusted
                  Property Management Firm is the key to protecting your real
                  estate investment and maximizing profitability. Allan &
                  Bradley has been providing Property Management Services to
                  satisfied property owners for over 10 years. Let the
                  experienced team take care of the details so that you can
                  enjoy the benefits of owning a rental investment property.
                </p>
                <h6>welcome to hendon residence showcase</h6>
              </div>
              <img src={abimg3} alt="" />
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-lg-7">
            <div className="about-image mr-5 rmr-0">
              <div className="experience-year">
                {/* <h4>Over</h4> */}
                <h3>Over</h3>
                <span className="number">10</span>
                <h4>Years of Experience</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="wp-content about-content mb-0 ml-10 pt-30 rmb-0">
              <div className="section-title mb-10">
                <h6>Welcome to Allan & Bradley.</h6>
                {/* <h2>
                  A small efficient <br />
                  <span>interior</span> <br />
                  <span className="thin">designing team</span>
                </h2> */}
                <p>
                  {" "}
                  Real Estate may be your single largest asset. Having a trusted
                  Property Management Firm is the key to protecting your real
                  estate investment and maximizing profitability. Allan &
                  Bradley has been providing Property Management Services to
                  satisfied property owners for over 10 years. Let the
                  experienced team take care of the details so that you can
                  enjoy the benefits of owning a rental investment property.
                </p>

                <div data-swiper-parallax="500" className="slide-btns">
                  <Link to="/about" className="theme-btn">
                    Discover More
                  </Link>
                </div>
                {/* <h6>welcome to hendon residence showcase.</h6> */}
              </div>
              <img src={abt1} alt=""  />

              {/* <img src={prop5} alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
