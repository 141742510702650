import pImg1 from '../images/apartment/apartment1.png'
import pImg2 from '../images/apartment/apartment2.png'
import pImg3 from '../images/apartment/apartment3.png'
import gal1 from '../images/Gallery/gallery1.JPG'
import gal2 from '../images/Gallery/gallery2.JPG'
import gal3 from '../images/Gallery/gallery3.JPG'
import gal4 from '../images/Gallery/gallery4.JPG'
import gal5 from '../images/Gallery/gallery5.JPG'
import gal6 from '../images/Gallery/gallery6.JPG'
import gal7 from '../images/Gallery/gallery7.JPG'
    import gal8 from '../images/Gallery/gallery8.JPG'
        import gal9 from '../images/Gallery/gallery9.JPG'
                import gal10 from '../images/Gallery/gallery10.JPG'

import pSImg1 from '../images/apartment/apartment1.png'
import pSImg2 from '../images/apartment/apartment2.png'
import pSImg3 from '../images/apartment/apartment3.png'

import sIcon1 from '../images/apartment/icon1.png'
import sIcon2 from '../images/apartment/icon2.png'
import sIcon3 from '../images/apartment/icon3.png'

import prop1 from '../images/allanB/prop1.jpg'

// import prop12 from '../images/allanB/prop12.jpg'
import prop13 from '../images/allanB/prop13.jpg'


const Projects = [
    {
        Id: '1',
        pTitle: 'Architecture', 
        slug: 'Architecture',
        pImg:gal1,
        pSImg:pSImg1,
        ssIcon:sIcon1,
        des:'Aenean vehicula non mauris maximus elementum. Nulla facilisi.',
        width:'120',
    },
    {
        Id: '2',
        pTitle: 'Exterior Design', 
        slug: 'Exterior-Design',
        pImg:gal2,
        pSImg:pSImg2,
        ssIcon:sIcon2,
        des:'Aenean vehicula non mauris maximus elementum. Nulla facilisi.',
        width:'130',
    },
    {
        Id: '3',
        pTitle: 'Interior', 
        slug: 'Interior',
        pImg:gal3,
        pSImg:pSImg3,
        ssIcon:sIcon3,
        des:'Aenean vehicula non mauris maximus elementum. Nulla facilisi.',
        width:'140',
    },
    {
        Id: '4',
        pTitle: 'Furniture & Decor', 
        slug: 'Furniture-&-Decor',
        pImg:gal4,
        pSImg:pSImg1,
        ssIcon:sIcon1,
        des:'Aenean vehicula non mauris maximus elementum. Nulla facilisi.',
        width:'120',
    },
    {
        Id: '5',
        pTitle: 'Exclusive Home Decor', 
        slug: 'Exclusive-Home-Decor',
        pImg:gal5,
        pSImg:pSImg2,
        ssIcon:sIcon2,
        des:'Aenean vehicula non mauris maximus elementum. Nulla facilisi.',
        width:'130',
    },
    {
        Id: '6',
        pTitle: 'Modern Architecture', 
        slug: 'Modern-Architecture',
        pImg:gal9,
        pSImg:pSImg3,
        ssIcon:sIcon3,
        des:'Aenean vehicula non mauris maximus elementum. Nulla facilisi.',
        width:'140',
    },
    {
        Id: '7',
        pTitle: 'Modern Architecture', 
        slug: 'Modern-Architecture',
        pImg:gal6,
        pSImg:pSImg3,
        ssIcon:sIcon3,
        des:'Aenean vehicula non mauris maximus elementum. Nulla facilisi.',
        width:'140',
    },  {
        Id: '8',
        pTitle: 'Modern Architecture', 
        slug: 'Modern-Architecture',
        pImg:gal7,
        pSImg:pSImg3,
        ssIcon:sIcon3,
        des:'Aenean vehicula non mauris maximus elementum. Nulla facilisi.',
        width:'140',
    },
    {
        Id: '9',
        pTitle: 'Modern Architecture', 
        slug: 'Modern-Architecture',
        pImg:gal8,
        pSImg:pSImg3,
        ssIcon:sIcon3,
        des:'Aenean vehicula non mauris maximus elementum. Nulla facilisi.',
        width:'140',
    },

]

export default Projects;