import React from 'react'
import abimg from '../../images/wp/wp-3.png'
import abt1 from "../../images/allanB/about-us.jpg";
import { Link } from 'react-router-dom';
import hmint from "../../images/allanB/home-intro.jpg"


const About2 = (props) => {

    return (
        <section className="about-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="about-image mr-50 rmr-0">
                            <div className="experience-year">
                            <h3>Over</h3>
                                <span className="number">10</span>
                                <h4>Years of Experience</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="wp-content about-content mb-10 ml-5 pt-20 rmb-50">
                            <div className="section-title mb-10">
                                <h6>About Us</h6>
                                {/* <h2>A small efficient <br />
                                    <span>interior</span> <br />
                                    <span className="thin">designing team</span>
                                </h2> */}
                                <h6>Professional property management you can trust.</h6>
                                <p>Allan & Bradley provides expert property management services and solutions across residential,
                                     commercial, and short-term rental management. These three pillars
                                     of property management provide owners with service options that build value and are unmatched in the industry.</p>
                            </div>
                            <img src={abt1} alt="" />
                            <div data-swiper-parallax="500" className="slide-btns pt-20">
                  <Link to="/about" className="theme-btn">
                    Discover More
                  </Link>
                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About2;