import simg from '../images/services/icon1.png'
import simg2 from '../images/services/icon2.png'
import simg3 from '../images/services/icon3.png'
import simg4 from '../images/services/icon4.png'
import simg5 from '../images/services/icon5.png'
import simg6 from '../images/services/icon6.png'
import simg7 from '../images/services/icon6.png'
import simg8 from '../images/services/icon6.png'



import sSimg1 from '../images/service-single/img-1.jpg'
import sSimg2 from '../images/service-single/img-2.jpg'
import sSimg3 from '../images/service-single/img-3.jpg'
import sSimg4 from '../images/service-single/img-1.jpg'
import sSimg5 from '../images/service-single/img-2.jpg'
import sSimg6 from '../images/service-single/img-3.jpg'
import sSimg7 from '../images/service-single/img-3.jpg'
import sSimg8 from '../images/service-single/img-3.jpg'
import hmint from "../images/allanB/home-intro.jpg"

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'

const Services = [
    {
        Id: '1',
        sImg:simg,
        sSimg:sSimg1,
        sTitle: 'Residential Property Management',
        slug: 'Residential Property Management',
        description:'  The oversight and operation of residential real estate, such as apartments, houses, and condominiums to ensure that the property  is well-maintained and generates maximum returns for the property owner or investors.',
        des2:'Effective residential property management requires a combination of strong organizational, communication, and problem-solving skills. Property managers act as intermediaries between property owners and tenants, striving to create a positive and well-maintained living environment.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:hmint,
        ssImg2:hmint,
    },
    {
        Id: '2',
        sImg:simg2,
        sSimg:sSimg2,
        sTitle: 'Commercial Property Management',
        slug: 'Commercial Property Management',
        description:'The management and oversight of commercial real estate, such as office buildings, retail centers, industrial facilities, and other non-residential properties.',
        des2:'Elementum aliquam byuerirra sagittis duis non quis.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImg:simg3,
        sSimg:sSimg3,
        sTitle: 'Short-Term Property Management',
        slug: 'Short-Term Property Management',
        description:' The management of residential or commercial properties for brief durations, typically for temporary stays by guests',
        des2:'Elementum aliquam byuerirra sagittis duis non quis.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        sImg:simg4,
        sSimg:sSimg4,
        sTitle: 'Rent Collection',
        slug: 'Rent Collection',
        description:' Ensuring that property owners receive timely payments from tenants',
        des2:'Elementum aliquam byuerirra sagittis duis non quis.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '5',
        sImg:simg5,
        sSimg:sSimg5,
        sTitle: 'Tenant Placement',
        slug: 'Tenant Placement',
        description:'Involves finding and securing suitable tenants for rental properties',
        des2:'Elementum aliquam byuerirra sagittis duis non quis.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '6',
        sImg:simg6,
        sSimg:sSimg6,
        sTitle: 'Rental property Marketing & Showcasing',
        slug: 'Rental property Marketing & Showcasing',
        description:'Marketing and showcasing rental properties play a crucial role in attracting potential tenants and maximizing occupancy.',
        des2:'Elementum aliquam byuerirra sagittis duis non quis.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '7',
        sImg:simg7,
        sSimg:sSimg8,
        sTitle: 'Sale of Property',
        slug: 'Sale of Property',
        description:'The sale of a property involves a series of steps to ensure a smooth and successful transaction',
        des2:'Elementum aliquam byuerirra sagittis duis non quis.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '8',
        sImg:simg3,
        sSimg:sSimg3,
        sTitle: 'Property Development',
        slug: 'Property Development',
        description:'The process of purchasing, improving, and selling real estate to make a profit. It can range from small-scale projects, such as renovating a single-family home, to large-scale developments, such as constructing residential or commercial complexes',
        des2:'Elementum aliquam byuerirra sagittis duis non quis.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
]
export default Services;