import React, {useState} from "react";
// import { Link } from "react-router-dom";
// import Masonry from "react-responsive-masonry"
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"



const images = [
    "../../images/allanB/ashley.webp",
    "../../images/allanB/prop1.jpg",
    "../../images/allanB/prop3.jpg",
    "../../images/allanB/prop5.jpg",
    "../../images/allanB/prop17.jpg",
    "../../images/allanB/prop9.jpg",
    "../../images/allanB/prop10.jpg",
    "../../images/allanB/prop13.jpg",
    "../../images/allanB/home-intro.jpg",
    '../images/allanB/new-slider-2.jpg',
    '../images/allanB/new-slider-1A&B.jpg',
    '../../images/allanB/tamarind.jpg',
     '../../images/allanB/fims1.webp',
     '../../images/allanB/nandiarcade.jpg',
     "../../images/allanB/prop9.jpg",


  

]

const Gallery = () => {

    const [data, setData] =useState({img:'',i:0});
    const viewImage =(img,i) => {
        setData({img,i})
        // console.log(img,i)  
    }

const ImageAction=(action)=>{
let i=data.i
if(action==='next-img'){
    setData({img:images[i+1],i:i+1})
}
if(action==='prev-img'){
    setData({img:images[i-1],i:i-1})
}
if(!action){
    setData({img:'',i:0})
}
}
    return (
        <>
        {data.img &&
        <div className="gallery-item">
            <button onClick={()=>ImageAction()} style={{position:'absolute', top:'10px',right:'10px',width:'20px',height:'20px',background:'white'}}>X
</button>
            <button onClick={()=>ImageAction('prev-img')}><i class="fa fa-chevron-left" aria-hidden="true"></i>

Previous</button>
        <img src={data.img} alt="Gallery-picture"  className="gallery-img" />
        <button onClick={()=>ImageAction('next-img')} >Next<i class="fa fa-chevron-right" aria-hidden="true"></i>
</button>
        </div>
        }
     

        <div className="gallery" >
          
        <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
        <Masonry gutter=""> 
            {images.map((image, i) => (
                <img
                className="gal-img"
                    key={i}
                    src={image}
                    style={{display:"block",padding: "10px",cursor: "pointer"}}
                    alt=""
                    onClick={()=>viewImage(image,i )}
                />
            ))}
        </Masonry>
                </ResponsiveMasonry>
    
       </div>
           </>
    );
};
export default Gallery;