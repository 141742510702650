import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import Navbar from '../../components/Navbar/Navbar'
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
// import Gallery from '../../components/Gallery/gallery';
import ProjectSectionS2 from '../../components/ProjectSectionS2/ProjectSectionS2';

const GalleryPage = () => {
    return (
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={'Gallery'} pagesub={'Gallery'} />
            {/* <ServiceSection ptClass={'pt-100'}/> */}
            {/* <About2 /> */}
            {/* <FunFact fClass={'style-two pt-165 pb-125 mb-0'}/> */}
            {/* <TeamSection/> */}
                        <ProjectSectionS2/>

            {/* <Gallery/> */}
            {/* <VideoSection /> */}
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default GalleryPage;
