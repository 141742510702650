import React from "react";
import ContactForm from "../ContactFrom";
import sIcon1 from "../../images/contact/icon1.png";
import sIcon2 from "../../images/contact/icon2.png";
import sIcon3 from "../../images/contact/icon3.png";

const Contactpage = () => {
  return (
    <div>
      <div class="contact-map pt-3" id="map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d15958.635245724703!2d35.26631014724481!3d0.5123361417222939!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d0.5142796!2d35.2697794!4m5!1s0x178101a6af82ae0b%3A0x6bba58c792cf1f5c!2sAllan%20%26%20Bradley%20Co.%20Ltd%2C%20Adjacent%20to%20St.%20Luke%20Hospital%2C%20Nandi%20Rd%2C%20Eldoret!3m2!1d0.5102188!2d35.283708499999996!5e0!3m2!1sen!2ske!4v1705475396169!5m2!1sen!2ske" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.9147703055!2d-74.11976314309273!3d40.69740344223377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew+York%2C+NY%2C+USA!5e0!3m2!1sen!2sbd!4v1547528325671"></iframe> */}
      </div>
      <div className="contact-info text-center mb-10">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="info-item">
              <i className="fa fa-map-marker"></i>

                {/* <img src={sIcon1} alt="Icon" /> */}
                <p> Next to St. Luke hospital, Off Nandi road Eldoret, Kenya</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="info-item">
              <i className="fa fa-phone"></i>

                {/* <img src={sIcon2} alt="Icon" /> */}
                <p><a href="tel:+25470277771">Call Us: <br></br>0702 77771</a></p>{" "}
                {/* <p>
                  Info@allanandbradley.co.ke <br />
                  666 888 0000
                </p> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="info-item">
              <i className="fa fa-envelope"></i>

                {/* <img src={sIcon3} alt="Icon" /> */}
                <p>
                 <a href="mailto:info@allanandbradley.co.ke">Email Us:<br></br>info@allanandbradley.co.ke</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
};

export default Contactpage;
