import React from "react";
import { Link } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import Bg from "../../images/footer.png";
import pImg1 from "../../images/instagram/instagram1.png";
import pImg2 from "../../images/instagram/instagram2.png";
import pImg3 from "../../images/instagram/instagram3.png";
import pImg4 from "../../images/instagram/instagram4.png";
import pImg5 from "../../images/instagram/instagram5.png";
import pImg6 from "../../images/instagram/instagram6.png";
import CtaSection from "../CtaSection/CtaSection";
import logo from "../../images/allanB/logoA&B.png";

const images = [pImg1, pImg2, pImg3, pImg4, pImg5, pImg6];
const SubmitHandler = (e) => {
    e.preventDefault()
}
const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <CtaSection />
      <footer
        className="footer-section pt-100"
        style={{ backgroundImage: `url(${Bg})` }}
      >
        <div className="container">
          <div className="row align-items-center">
            {/* <div className="col-xl-6 col-lg-8">
              <div className="instagram-posts">
                {images.map((item, pitem) => (
                  <div className="instagram-item" key={pitem}>
                    <img
                      src={item}
                      alt="Instagram"
                      onClick={() => setOpen(true)}
                    />
                    <div className="instagram-overlay">
                      <span onClick={() => setOpen(true)}>
                        <i className="fa fa-instagram"></i>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
            <div className="col-xl-4 col-lg-4">
              <div className="contact-widget">
                {/* <h3>Contact</h3> */}
                <img src={logo} alt="" />
                <h6>Professional property management you can trust.</h6>
                <h6>.</h6>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4">
              <div className="contact-widget">
                {/* <h3>Contact</h3> */}
                <ul>
                <li>
                    {" "}
                    <Link onClick={ClickHandler} to="/">
                      {" "}
                     Home
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link onClick={ClickHandler} to="/">
                      {" "}
                      About Us
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link onClick={ClickHandler} to="/services">
                      Services
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link onClick={ClickHandler} to="/blog">
                      {" "}
                    Blog
                    </Link>
                  </li>
                </ul>
                <h6>
                
                  Subscribe to our monthly newsletter.
                </h6>
                <form onSubmit={SubmitHandler} className="subscribe">
                                <input type="email" placeholder="Email address" required/>
                                {/* <button type="submit" className="theme-btn style-two">u</button> */}
                            </form>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4">
              <div className="contact-widget">
                <h3>About Us </h3>
                <h6>
                  Find out all the ways to enjoy luxury residential life.
                </h6>
                <h6>Follow Us On</h6>
                <div className="bottom-inner">
                  <div className="social-icons">
                    <a onClick={ClickHandler} href="https://twitter.com/AllanBradley356">
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a onClick={ClickHandler} href="https://web.facebook.com/profile.php?id=61555338317336">
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a onClick={ClickHandler} href="https://www.instagram.com/allanbradleycompany">
                      <i className="fa fa-instagram"></i>
                    </a>
                    {/* <Link onClick={ClickHandler} to="/">
                      <i className="fa fa-pinterest"></i>
                    </Link> */}
                  </div>
                  {/* <div className="copyright">
                <p>
                  © Copyright 2023 by{" "}
                  <Link onClick={ClickHandler} to="/">
                    Allanandbradley.co.ke   
                  </Link>
                </p>
              </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom mt-2 text-center">
          <div className="container">
          
              
              <div className="copyright">
                <p>
                <div>&copy;  {new Date().getFullYear()}  {" "}  <Link onClick={ClickHandler} to="/">
                   Allan & Bradley Company Limited.
                  </Link> All Rights Reserved. Designed by  {" "}
                  <Link onClick={ClickHandler} to="/https://lancolatech.com/">
                  Lancolatech.
                  </Link></div>
                  
                 
                </p>
              </div>
            
          </div>
        </div>
        {/* <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={[
            // { src: pImg1 },
            { src: pImg2 },
            { src: pImg3 },
            { src: pImg4 },
            { src: pImg5 },
            { src: pImg6 },
          ]}
          plugins={[Zoom]}
        /> */}
      </footer>
    </div>
  );
};

export default Footer;
